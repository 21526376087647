<template>
    <div>
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>

<script>

export default {
    name:'MainView',
    data(){
        return {}
    },
    computed:{},
    methods:{},
    filters:{},
    props:{},
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style>
</style>